import { isUserSignedIn } from '@/utils/general/user'
import { Raven } from '.'
import { isEmpty } from '@/utils/general/browserHelper'
import { dayDiff, formatDateStandardized, getFormattedDate } from '@/utils/general/date'
import {
    checkIfAnyAncilliarySelected,
    getInsuranceData
} from '@/components/itinerary/Addons/MealBaggage/AddonsHelperUtils'
import { getCookie } from '@/utils/general/cookie'
import { SearchData } from '@/types/srp'
import { ProcessedData } from '@/utils/srp/types'
import { RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'

export const stringifyPayload = (payload: any) => {
    const keys = Object.keys(payload)
    keys.forEach(key => {
        if (
            key === 'a_fare_price' ||
            key === 'a_ct_discount' ||
            key === 'supercoin_balance' ||
            key === 'supercoin_earned' ||
            key === 'supercoin_burnt' ||
            key === 'wallet_balance_used' ||
            key === 'convenience_fee'
        )
            payload[key] = Number(payload[key])
        else {
            payload[key] = '' + payload[key]
            payload[key] = payload[key].toLowerCase()
        }
    })
    return payload
}

export const extraRavenPayload = (itineraryData: any) => {
    const {
        REWARDS = [],
        COUPON: { appliedCouponDetails = {} } = {},
        ANCILLARY = {},
        INSURANCES = {}
    } = itineraryData || {}
    const rewardData = REWARDS?.filter((reward: any) => {
        return reward.rewardType === 'SUPERCOINS'
    })?.[0]?.rewardsData
    const insuranceData = getInsuranceData(INSURANCES)
    const extraPayload = {
        a_smb_booked:
            checkIfAnyAncilliarySelected(ANCILLARY, 'SEAT', insuranceData) ||
            checkIfAnyAncilliarySelected(ANCILLARY, 'MEAL', insuranceData) ||
            checkIfAnyAncilliarySelected(ANCILLARY, 'BAGGAGE', insuranceData)
                ? 'yes'
                : 'no',
        a_tb_insurance_booked: checkIfAnyAncilliarySelected(ANCILLARY, 'INSURANCE', insuranceData) ? 'yes' : 'no',
        ct_coupon_applied: appliedCouponDetails?.couponData?.[0]?.couponCode || '',
        supercoin_burnt: rewardData?.superCoinDetail?.balanceDetails?.redeemableSuperCoinAmount || 0,
        supercoin_earned: rewardData?.superCoinDetail?.earnConfigDetails?.maxEarnCoins || 0
    }
    return extraPayload
}

export const superCoinPayload = (itineraryData: any) => {
    const { REWARDS = [] } = itineraryData || {}
    const rewardData = REWARDS?.filter((reward: any) => {
        return reward.rewardType === 'SUPERCOINS'
    })?.[0]?.rewardsData
    const payload = {
        supercoin_account_status: rewardData?.superCoinDetail?.status === 'SUCCESS' ? 'yes' : 'no',
        supercoin_balance: rewardData?.superCoinDetail?.balanceDetails?.superCoinBalance || 0,
        supercoin_burnt: rewardData?.superCoinDetail?.balanceDetails?.redeemableSuperCoinAmount || 0
    }
    return payload
}

export const ravenSDKTrigger = (eventName: string, ravenPayload: any) => {
    const commonPayload = {
        platform: 'desktop',
        login_status: isUserSignedIn() ? 'yes' : 'no',
        domain: window.location.host,
        vertical: 'air'
    }
    const newRavenPayload = stringifyPayload(ravenPayload)
    Raven.push({
        eventName: eventName,
        eventData: { ...newRavenPayload, ...commonPayload }
    })
}

export const ravenSDKItinTrigger = (eventName: string, itineraryData: any, additionpayload: any) => {
    const {
        SEARCH_DETAILS = {},
        FLIGHT: { flights = {} } = {},
        FARE_CARDS: { fareCards = {} } = {},
        BANNERS: { MULTI_LOYALTY = {} } = {}
    } = itineraryData || {}
    const flightNumbers: any[] = []
    const airlineNames: any[] = []
    let itineraryId = ''
    if (typeof window !== 'undefined') {
        const locationArray = window?.location?.pathname?.split('/') || []
        itineraryId = locationArray?.[locationArray?.length - 2] || ''
    }
    const { programDetails = [] } = MULTI_LOYALTY || {}
    if (!isEmpty(flights)) {
        flights.forEach((flight: { segments: any[] }) =>
            flight.segments.forEach(segment => {
                flightNumbers.push(segment.airline + '-' + segment.flightNumber)
                airlineNames.push(segment.airlineName)
            })
        )
        const selectedFareCard = fareCards?.filter?.((fareCard: any) => {
            return fareCard.selected === true
        })?.[0]
        let flightNumbersValue = flightNumbers.length > 1 ? flightNumbers.join(',') : flightNumbers[0]
        let airlineNameValue = airlineNames.length > 1 ? airlineNames.join(',') : airlineNames[0]
        const commonItinPayload = {
            a_trip_type: SEARCH_DETAILS?.sectorType === 'DOM' ? 'domestic' : 'international',
            a_journey_type: SEARCH_DETAILS?.journeyType === 'ONE_WAY' ? 'ow' : 'rt',
            a_origin: flights?.[0]?.departureAirportCode || 'NA',
            a_itinerary_id: itineraryId,
            a_destination: flights?.[0]?.arrivalAirportCode || 'NA',
            a_airline: airlineNameValue,
            a_flight_number: flightNumbersValue,
            a_ff_fare_type: SEARCH_DETAILS?.fareType || 'N/A',
            is_loyalty_eligible: isEmpty(programDetails) ? 'no' : 'yes',
            loyalty_fare_type: programDetails?.[0]?.programName || 'N/A',
            isAmendment: window.location.pathname == '/flights/amendments' ? true : false
        }
        let finalPayload = { ...commonItinPayload }
        if (
            eventName === 'a_continue_payment_click' ||
            eventName === 'a_itinerary_continue_click' ||
            eventName === 'a_traveller_continue_clicked' ||
            eventName === 'a_add_on_continue_click'
        ) {
            finalPayload = { ...finalPayload, ...extraRavenPayload(itineraryData) }
        }
        if (
            eventName === 'a_itinerary_pageload' ||
            eventName === 'a_itinerary_continue_click' ||
            additionpayload.action_name === 'supercoin_toggle_on_click' ||
            additionpayload.action_name === 'supercoin_toggle_off_click'
        ) {
            finalPayload = { ...finalPayload, ...superCoinPayload(itineraryData) }
        }

        ravenSDKTrigger(eventName, { ...additionpayload, ...finalPayload })
    }
}

export const ravenSDKItinPageLoad = (eventName: string, itineraryData: any, additionpayload: any) => {
    const {
        SEARCH_DETAILS = {},
        FLIGHT: { flights = {} } = {},
        FARE_CARDS: { fareCards = {} } = {}
    } = itineraryData || {}
    const adultCount = SEARCH_DETAILS?.paxInfo?.adults || 0
    const childCount = SEARCH_DETAILS?.paxInfo?.children || 0
    const infantCount = SEARCH_DETAILS?.paxInfo?.infants || 0
    if (flights) {
        const depDate = flights?.[0]?.segments?.[0]?.departDateTime || ''
        const returnDate = flights?.[1]?.segments?.[0]?.departDateTime || ''
        const selectedFareCard = fareCards?.filter?.((fareCard: any) => {
            return fareCard.selected === true
        })?.[0]
        const pageLoadPayLoad = {
            a_utm_source: SEARCH_DETAILS?.utmSource || 'organic',
            a_search_id: SEARCH_DETAILS?.searchId || 'N/A',
            a_return_date: returnDate
                ? formatDateStandardized(getFormattedDate(returnDate, 'DD/MM/YYYY', 'YYYY/MM/DD'))
                : 'N/A',
            a_stop_count_onward_return:
                flights?.[1]?.stopCount || flights?.[1]?.stopCount === 0
                    ? flights?.[0]?.stopCount + ',' + flights?.[1]?.stopCount
                    : flights?.[0]?.stopCount + '',
            a_dx: dayDiff(
                new Date(
                    getFormattedDate(flights?.[0]?.segments?.[0]?.departDateTime || '', 'MM/DD/YYYY', 'YYYY/MM/DD')
                ),
                new Date()
            ),
            a_adult_count: adultCount,
            a_fare_type: SEARCH_DETAILS?.fareType || 'N/A',
            a_child_count: childCount,
            a_infant_count: infantCount,
            a_departure_date: depDate
                ? formatDateStandardized(getFormattedDate(depDate, 'DD/MM/YYYY', 'YYYY/MM/DD'))
                : 'N/A',
            a_ff_fare_type: SEARCH_DETAILS?.fareType || 'N/A'
        }
        let finalPayload = { ...pageLoadPayLoad }
        if (eventName === 'a_flight_travellers_review_pageload' || eventName === 'a_traveller_pageload') {
            finalPayload = { ...finalPayload, ...extraRavenPayload(itineraryData) }
        }
        ravenSDKItinTrigger(eventName, itineraryData, { ...finalPayload, ...additionpayload })
    }
}

export const ravenSDKSrpCfwTrigger = (
    eventName: string,
    resultsData: SearchData,
    searchQuery: Partial<ProcessedData>,
    additionpayload: Record<string, string | number>
) => {
    const payload = {
        a_utm_source:
            location?.search?.split?.('utm_source=').length > 1
                ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                : getCookie('utm_source') || 'organic',
        last_page_name: window.location.search?.includes?.('mevc=y')
            ? 'alternate_dates_flights_click'
            : location?.search?.split?.('utm_source=').length > 1
            ? location?.search?.split('utm_source=')?.pop()?.split('&')[0]
            : location?.search?.split?.('dealId=').length > 1
            ? 'dealszone'
            : 'home_page',
        a_search_id: resultsData?.searchId || 'N/A',
        a_trip_type: searchQuery?.isIntl ? 'international' : 'domestic',
        a_journey_type: searchQuery?.returnDate ? 'rt' : 'ow',
        a_return_date: searchQuery?.returnDate || 'N/A',
        a_dx: dayDiff(
            new Date(getFormattedDate(searchQuery?.departDate || '', 'MM/DD/YYYY', 'DD/MM/YYYY')),
            new Date()
        ),
        a_adult_count: searchQuery?.travellers?.adults || 0,
        a_origin: searchQuery?.source || 'N/A',
        a_result_count:
            (resultsData?.cards?.J1 ? resultsData?.cards?.J1?.length : 0) +
            (resultsData?.cards?.J2 ? resultsData?.cards?.J2?.length : 0),
        a_child_count: searchQuery?.travellers?.children || 0,
        a_infant_count: searchQuery?.travellers?.infants || 0,
        a_departure_date: searchQuery?.departDate || 'N/A',
        a_destination: searchQuery?.destination || 'N/A',
        isAmendment: window.location.pathname == '/flights/amendments' ? true : false,
        is_cfw_user: searchQuery?.isCfw ? 'yes' : 'no',
        is_loyalty_eligible: '',
        a_is_sort_applied: 'yes',
        a_is_filter_applied: '',
        a_min_price: resultsData?.filterData?.oneWayPrice?.min|| 'N/A',
        page_name: RAVEN_PAGE_NAME.FLIGHTS_SRP
    }

    ravenSDKTrigger(eventName, { ...payload, ...additionpayload })
}
