import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import popular from '@/mocks/popularCitiesSearch.json'
import './index.scss'

import AirportField from './AirportField'
import {
    debounce,
    getflightsHistory,
    htmlSanitizer,
    isEmpty,
    replaceQueryInUrl,
    resolveKeysInObjectsArrays,
    toQueryString,
    updateHomeSearch
} from '@/utils/general/browserHelper'
import baseFetch from '@/network/baseFetch'
import { HOME_API_ENDPOINTS, REQUEST_METHODS } from '@/constants/network'
import { getApiDomain, getUrlWithArgs } from '@/utils/general/url'
import { ISuggestion } from '@/types/home'
import { Button, Container, Spacer } from '@cleartrip/ct-design-components'
import DateRangePicker from '@/components/oldComponents/DateRangePicker'
import { dateDiff, dayDiff, formatDateStandardized, getFormattedDate } from '@/utils/general/date'
import JourneyType from '@/components/home/JourneyType'
import TravellerAndClassDropdown from '@/components/home/TravellersAndClass'
import SplFareType from '@/components/home/SpecialFare'
import { FareTypeInterface, FARE_TYPE, returnFareName } from '@/constants/components/fareType'
import { RAVEN_ATTRIBUTE_NAME, RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'
import { Raven } from '@/analytics/raven'
import { useAppDispatch, useAppSelector } from '@/redux/store/hooks'
import { getAbData } from '@/redux/slices/abConfig'
import { GaDataPush, dataLayerGA, getInitialDataLayerParams } from '@/analytics/GA/dataLayerHelper'
import { isUserSignedIn } from '@/utils/general/user'
import Clevertap from '@/analytics/clevertap/index'
import { ACTION_NAMES } from '@/constants/analytics'
import { HomeFormType } from '@/components/home/HomeForm'
import { homePageLoad } from '@/analytics/clevertap/events'
import { defaultAbValues } from '@/constants/base'
import CFWHomePageCallout from '@/components/home/CFW/HomePageCallout'
import { isCfwFlow } from '@/utils/cfw'
import CfwErrorModal from '@/components/home/CFW/CfwErrorModal/index'
// import CfwModal from ''
import { isServer } from '@/utils'
import dynamic from 'next/dynamic'
const CfwModalSSr = dynamic(import('@/components/home/CFW/HomePageCallout/CfwModal'), {
    ssr: false,
    loading: () => <></>
})
import { ActionMapper } from '@/types'
import { getActionPayload } from '@/utils/general/actions'
import {isFFFlowEnabled} from "@/utils/common";
import { useRouter } from 'next/router'
import Statsig from 'statsig-js'
import { setCookie } from '@/utils/general/cookie'
import { checkPhoneNumberWhitelisting } from '@/utils/experiment/phoneNumberWhitelisting'
import { checkInternalCTUser } from '@/utils/srp'
export interface queryFormat {
    adults: string
    childs: string
    infants: string
    class: string
    depart_date?: string
    return_date?: string
    from?: string
    to?: string
    intl?: string
}

interface CityState {
    source: string
    destination: string
    sourceCountry: string
    destinationCountry: string
    sCode: string
    dCode: string
    isIntl: string
    departureError: boolean
    arrivalError: boolean
    intl?: string
}
export interface srpQueryObject {
    adults: string
    childs: string
    infants: string
    class: string
    depart_date?: string
    return_date?: string
    from: string
    to: string
    intl: string
    origin: string
    destination: string
    sft: string
    sd: number
    rnd_one: string
    isCfw: boolean
    isFF?: boolean
    setShowVisaBanner: (arg1: boolean) => void
}

const HomeSearchContainer: FC<HomeFormType> = ({ query, setQuery, setShowVisaBanner }) => {
    const [initialTS, setInitialTS] = useState(new Date().getTime())
    const [airportSearchList, setAirportList] = useState({ departure: popular, arrival: popular })
    let [departure, setDeparture] = useState('')
    const [isIntlSelected, setIsIntlSelected] = useState([false, false])
    let [arrival, setArrival] = useState('')
    const [tripType, setTripSelect] = useState('O')
    const [cfwErrorModal, setCfwErrorModal] = useState('')
    const [cfwErrorModalButtonText, setCfwErrorModalButtonText] = useState('Okay, got it')
    const [isCfwSelected, setIsCfwSelected] = useState(false)
    // const [query, setQuery] = useState<queryFormat>({ adults: '1', childs: '0', infants: '0', class: 'Economy' })
    const [fareList, setFareList] = useState(FARE_TYPE)
    const [isIntlArray, setIsIntlArray] = useState([false, false])
    const [activeFare, setActiveFare] = useState('')
    const [cfwModal, setCfwModal] = useState(false)
    const browserHistory = useRouter()
    const [city, setCity] = useState<CityState>({
        source: '',
        destination: '',
        sourceCountry: '',
        destinationCountry: '',
        sCode: '',
        dCode: '',
        isIntl: '',
        departureError: false,
        arrivalError: false
    })
    const [loading, setloading] = useState(false)
    const [toast, setToast] = useState({})
    const abData = useAppSelector(getAbData)
    const [getAbTest, setAbTest] = useState({
        // e_home: 'z', ctUpgrade: 'c',
        ...defaultAbValues
    })
    const globalDispatcher = useAppDispatch()

    useEffect(() => {
            setCookie({ name: 'ffEnabled', value: 'false' })
    },[])
    useEffect(() => {
        setInitialTS(new Date().getTime())
        let flightHistory = getflightsHistory()

        const ravenPayLoad = {
            page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
            a_utm_source:
                location?.search?.split?.('utm_source=').length > 1
                    ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                    : 'organic',
            page_loadtime: new Date().getTime() - initialTS || 'N/A',
            last_page_name:
                location?.search?.split?.('utm_source=').length > 1
                    ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                    : '',
            search_details_prefilled: localStorage.getItem('fsHistory') ? 'yes' : 'no'
        }
        // if (abData) {
            homePageLoad({ initialLoadTime: initialTS, getAbTest: abData, abhome: abData?.tk_home })
            // Raven.push({ eventName: RAVEN_EVENT_NAME.HOME_PAGELOAD, eventData: ravenPayLoad })
            // setAbTest({ ...getAbTest, ...abData })
        // } else {
        //     homePageLoad({ initialLoadTime: initialTS })
        //     Raven.push({ eventName: RAVEN_EVENT_NAME.HOME_PAGELOAD, eventData: ravenPayLoad })
        // }
         let dataLayerValues = getInitialDataLayerParams('home')
        let dataLayerEventName = 'Home_Page'
        if (window && window.location && window.location.href && window.location.href.indexOf('/flights') > -1) {
            dataLayerValues = getInitialDataLayerParams('flights')
            dataLayerEventName = 'Flights_Home'
        }
       
        if (!isEmpty(flightHistory)) {
            if (flightHistory[0].rnd_one !== 'MC') {
                let date = getFormattedDate(new Date(), 'DD/MM/YYYY')
                let departDate = flightHistory[0].depart_date
                let returnDate = flightHistory[0].return_date
                let diff = dateDiff(date, departDate, 'DD/MM/YYYY')
                if (diff < 0) {
                    flightHistory[0].depart_date = date
                }
                if (!isEmpty(returnDate)) {
                    diff = dateDiff(date, returnDate, 'DD/MM/YYYY')
                    if (diff < 0) {
                        flightHistory[0].return_date = date
                    }
                }
                setQuery({
                    ...query,
                    ...flightHistory[0]
                })
                setTripSelect(flightHistory[0].rnd_one)
                setDeparture(flightHistory[0].origin)
                setArrival(flightHistory[0].destination)
                setCity({
                    ...city,
                    ...flightHistory[0],
                    sCode: flightHistory[0].from,
                    source: flightHistory[0].sourceCountry,
                    destination: flightHistory[0].destinationCountry,
                    dCode: flightHistory[0].to
                })
                if(!(browserHistory?.query?.['airCFW-reactivation'] === 'true')){
                    setIsCfwSelected(flightHistory[0].isCfw)
                }
                setActiveFare(flightHistory[0].sft || "")
            } else if (query.depart_date === 'Invalid Date' || isEmpty(query.depart_date)) {
                setQuery({
                    ...query,
                    depart_date: getFormattedDate(new Date(), 'DD/MM/YYYY')
                })
            }
        } else if (query.depart_date === 'Invalid Date' || isEmpty(query.depart_date)) {
            setQuery({
                ...query,
                depart_date: getFormattedDate(new Date(), 'DD/MM/YYYY')
            })
        }
    }, [])

    

    useEffect(() => {
        const gaPayload = {
            section: 'Flights'
        }
        GaDataPush('event', ACTION_NAMES.hpPageLandGA, gaPayload)
        dataLayerGA(gaPayload, 'home_page_land')
        // raven event experiment
        let ravenPayload = {
            platform: 'desktop',
            login_status: isUserSignedIn() ? 'yes' : 'no',
            domain: resolveKeysInObjectsArrays(['location', 'hostname'], window),
            vertical: 'air',
            page_name: 'flights_home'
        }
        Raven.push({ eventName: RAVEN_EVENT_NAME.HOME_M_HOMEPAGE_LOAD, eventData: ravenPayload })
        Clevertap.event(ACTION_NAMES.wrHomepageLoad, { ...ravenPayload })
        dataLayerGA({ ...ravenPayload }, ACTION_NAMES.wrHomepageLoad)
        // end of raven event experiment
    }, [])

    useEffect(() => {
        if (parseInt(query.childs) > 0 || parseInt(query.infants) > 0) {
            setFareList(fareFilter(false))
        } else {
            setFareList(fareFilter(true))
        }
        if (city.isIntl === 'y' || (isEmpty(city.isIntl) && city.intl === 'y')) {
            setActiveFare('')
            let tempfareList = JSON.parse(JSON.stringify(fareList))
            tempfareList.map((fare: FareTypeInterface) => {
                if (!(fare.code === '')) {
                    fare.active = false
                    fare.toolTipErrorMsg =
                        'Special fares like student, senior citizen and armed forces are applicable only for domestic flights'
                }
            })
            setFareList(tempfareList)
        }
    }, [query.childs, query.infants, city])

    function fareFilter(enabled: boolean) {
        const fareType = fareList.map(item => {
            if (item.code === 'senior_citizen' || item.code === 'student') {
                item.active = enabled
                if (!enabled) item.toolTipErrorMsg = `You cannot ${item.fare} with infants/child added to the journey`
            } else {
                item.active = true
            }
            return item
        })
        return fareType
    }
    const timerRef = useRef<number | NodeJS.Timeout | null>(null)

    useEffect(() => {
        if ((isIntlSelected[0] || isIntlSelected[1]) && isCfwSelected) {
            clearTimeout(timerRef.current as number)
            timerRef.current = setTimeout(() => {
                setCfwModal(false)
                setCfwErrorModal('Cleartrip for Work can only be accessed for domestic flights')
                setCfwErrorModalButtonText('Okay, got it')
            }, 100)
        }
    }, [isIntlSelected])

    useEffect(() => {
        isVisaBannerAllowed()
    }, [query, city])

    function changeDeparture(event: ChangeEvent<HTMLInputElement>) {
        let arrivalIntl = false
        if (event.target.value.length === 0) {
            setAirportList({
                ...airportSearchList,
                ['departure']: popular
            })
            if (!isEmpty(arrival)) arrivalIntl = arrival?.split(', ')?.pop()?.trim() !== 'IN'
            setCity({
                ...city,
                arrivalError: false,
                dCode: '',
                destination: '',
                isIntl: arrivalIntl ? 'y' : 'n'
            })
        }
        if (event.target.value.length !== 0)
            setCity({
                ...city,
                sCode: '',
                source: '',
                departureError: false
            })
        const departure = htmlSanitizer(event.target.value)
        setDeparture(departure)
        if (departure) {
            debounce(airportList, 400)(departure, 'departure')
        }
    }

    function changeArrival(event: ChangeEvent<HTMLInputElement>) {
        let departureIntl = false
        if (event.target.value.length === 0) {
            setAirportList({
                ...airportSearchList,
                ['arrival']: popular
            })
            if (!isEmpty(departure)) {
                departureIntl = departure?.split(', ')?.pop()?.trim() !== 'IN'
            }
            setCity({
                ...city,
                arrivalError: false,
                dCode: '',
                destination: '',
                isIntl: departureIntl ? 'y' : 'n'
            })
        }
        if (event.target.value.length !== 0)
            setCity({
                ...city,
                arrivalError: false,
                dCode: '',
                destination: ''
            })
        const arrival = htmlSanitizer(event.target.value)
        setArrival(arrival)
        if (arrival) {
            debounce(airportList, 400)(arrival, 'arrival')
        }
    }
    async function airportList(string: string, type: string) {
        let url = getUrlWithArgs(HOME_API_ENDPOINTS.AUTO_SUGGEST, string)
        try {
            setloading(true)
            let searchResult = await baseFetch<ISuggestion[]>({
                url: url,
                method: REQUEST_METHODS.GET,
                domain: getApiDomain()
            })

            //  airportSearch(string)
            if (searchResult) {
                setAirportList({
                    ...airportSearchList,
                    [type]: searchResult
                })
                if (type === 'departure') {
                    if (searchResult?.length === 0) {
                        Raven.push({
                            eventName: RAVEN_EVENT_NAME.HOME_DEPARTURE_AIRPORT_NULL_SEARCH,
                            eventData: {
                                action_name: RAVEN_ATTRIBUTE_NAME.HP_DEPARTURE_AIRPORT_NULL_SEARCH,
                                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
                                search_value: string
                            }
                        })
                    }
                } else {
                    if (searchResult?.length === 0) {
                        Raven.push({
                            eventName: RAVEN_EVENT_NAME.HOME_ARRIVAL_AIRPORT_NULL_SEARCH,
                            eventData: {
                                action_name: RAVEN_ATTRIBUTE_NAME.HP_ARRIVAL_AIRPORT_NULL_SEARCH,
                                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
                                search_value: string
                            }
                        })
                    }
                }

                setloading(false)
            }
        } catch (e) {
            console.log('airportList api error', e)
        }
    }
    function selectArrival(item: ISuggestion, name: string) {
        let code = item.k
        let value = item.v.split(' -')[0]
        let label = code + ' - ' + value
        let isIntl = value?.split(', ')?.pop()?.trim() !== 'IN'
        setArrival(label)
        let destination = value.split(',')[0]

        let departureIntl = departure?.split(', ')?.pop()?.trim() !== 'IN'
        if (!isEmpty(departure) && departureIntl && !isIntl) {
            isIntl = true
        }
        setIsIntlSelected([value?.split(', ')?.pop()?.trim() !== 'IN', isIntlSelected[1]])
        setCity({
            ...city,
            dCode: code,
            arrivalError: false,
            destination,
            isIntl: isIntl ? 'y' : 'n'
        })
        setToast({
            errorMsg: '',
            closeBtn: true
        })
        Raven.push({
            eventName: RAVEN_EVENT_NAME.HOME_ARRIVAL_AIRPORT_SELECTED,
            eventData: {
                action_name: RAVEN_ATTRIBUTE_NAME.HP_ARRIVAL_AIRPORT_SELECTED,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
            }
        })
    }
    function selectDeparture(item: ISuggestion) {
        let code = item.k
        let value = item.v.split(' -')[0]
        let label = code + ' - ' + value
        let isIntl = value?.split(', ')?.pop()?.trim() !== 'IN'
        setDeparture(label)
        let source = value.split(',')[0]

        let arrivalIntl = arrival?.split(', ')?.pop()?.trim() !== 'IN'
        if (!isEmpty(arrival) && arrivalIntl && !isIntl) {
            isIntl = true
        }
        setIsIntlSelected([isIntlSelected[0], value?.split(', ')?.pop()?.trim() !== 'IN'])
        setCity({
            ...city,
            source,
            sCode: code,
            departureError: false,
            isIntl: isIntl ? 'y' : 'n'
        })
        setToast({
            errorMsg: '',
            closeBtn: true
        })
        Raven.push({
            eventName: RAVEN_EVENT_NAME.HOME_DEPARTURE_AIRPORT_SELECTED,
            eventData: {
                action_name: RAVEN_ATTRIBUTE_NAME.HP_DEPARTURE_AIRPORT_SELECTED,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
            }
        })
    }

    function dateClick(date: { to: string; from: string }) {
        if (date.to) {
            setTripSelect('R')
            setQuery({
                ...query,
                depart_date: getFormattedDate(date.from, 'DD/MM/YYYY'),
                return_date: getFormattedDate(date.to, 'DD/MM/YYYY')
            })
        } else {
            setQuery({
                ...query,
                depart_date: getFormattedDate(date.from, 'DD/MM/YYYY')
            })
        }
    }
    function setActiveFareHandler(fareType: string) {
        if (isIntlArray[0] || isIntlArray[1]) {
            setCfwErrorModal(
                'Special fare like student, senior citizen and armed forces are applicable only for domestic flights'
            )
            setCfwErrorModalButtonText('Okay, got it')
            return
        }

        if (isCfwSelected) {
            if (fareType === 'senior_citizen') {
                setCfwErrorModal('Senior citizen fare doesn’t include the benefits of Cleartrip for work')
                setCfwErrorModalButtonText('Switch to Senior citizen fare')
            } else if (fareType === 'student') {
                setCfwErrorModal('Student fare doesn’t include the benefits of Cleartrip for work')
                setCfwErrorModalButtonText('Switch to Student fare')
            } else if (fareType === 'army') {
                setCfwErrorModal('Armed forces fare doesn’t include the benefits of Cleartrip for work')
                setCfwErrorModalButtonText('Switch to Armed forces fare')
            }
            return
        }

        if(activeFare === fareType){
            setActiveFare('')
            return
        }

        setActiveFare(fareType)
        Clevertap.event('Air_UI_Action', {
            action_name: ACTION_NAMES.ptcSelected,
            action_type: 'button',
            ptc_fare: returnFareName(fareType),
            is_ptc_fare_selected: returnFareName(fareType) === 'Regular fare' ? 'no' : 'yes',
            journey_type_uiaction: tripType === 'O' ? 'one-way' : 'round-trip'
        })
    }
    function tripTypeHandler(type: string) {
        setTripSelect(type)
        if (isEmpty(query.depart_date)) {
            setQuery({
                ...query,
                depart_date: getFormattedDate(new Date(), 'DD/MM/YYYY'),
                return_date: ''
            })
        }
        if (type == 'O') {
            setQuery({
                ...query,
                return_date: ''
            })
            Clevertap.event('Air_UI_Action', {
                action_type: 'BUTTON',
                action_name: ACTION_NAMES.hpRadioOW
            })
        }
        if (type == 'R') {
            Clevertap.event('Air_UI_Action', {
                action_type: 'BUTTON',
                action_name: ACTION_NAMES.hpRadioRT
            })
            if (isEmpty(query.return_date))
                setQuery({
                    ...query,
                    return_date: query.depart_date
                })
        }
    }
    function getUTM(): { [key: string]: string } {
        let searchURL = location.search.split('?')[1] || ''
        const andSepArr = searchURL.split('&').filter(ele => ele.includes('utm_'))
        let utmKeyValPair: string[][] = []

        andSepArr.map(ele => {
            utmKeyValPair.push(ele.split('='))
        })

        let utmObj: { [key: string]: string } = {}
        utmKeyValPair.map(ele => {
            utmObj[ele[0]] = ele[1]
        })

        return utmObj
    }
    function errorHandler(queryObject: srpQueryObject) {
        let validate = false
        let msg = ''
        if (queryObject.from == queryObject.to) {
            msg = 'Departure and arrival airports / cities cannot be same.'
            validate = true
        }

        if (!queryObject.from) {
            msg = 'Enter departure airport / city'
            validate = true
            setCity({
                ...city,
                departureError: true
            })
        }
        if (!queryObject.to) {
            msg = 'Enter arrival airport / city'
            validate = true
            setCity({
                ...city,
                arrivalError: true
            })
        }
        if (!queryObject.from && !queryObject.to) {
            msg = 'Enter departure and arrival airports / cities'
            validate = true
            setCity({
                ...city,
                departureError: true,
                arrivalError: true
            })
        }

        return { validate, msg }
    }
    async function searchHandler() {
        const checkIsIntl = !isEmpty(city.isIntl) ? city.isIntl : city.intl
        const ffVar = Statsig?.getExperiment("fare_family_desktop")?.value?.variant
        let isFFEnabled = false
        // const isPhoneNumberValid = await checkPhoneNumberWhitelisting('FF')
        const ffCheck = (checkIsIntl === 'n') && !(isCfwSelected && isCfwFlow({ isLoginMandatory: false }))
        if ((ffVar === 'ff_enabled') && ffCheck) { 
            setCookie({ name: 'ffEnabled', value: 'true' })
            isFFEnabled = true
        }
        if (!cfwModal && isCfwSelected) {
            setCfwModal(true)
            return
        }
        let queryObject: srpQueryObject = {
            ...query,
            from: '',
            to: '',
            intl: '',
            origin: '',
            destination: '',
            sft: '',
            sd: 0,
            rnd_one: '',
            isCfw: false,
            isFF: false
        }
        queryObject.from = city.sCode
        queryObject.to = city.dCode
        queryObject.intl = city.isIntl || city.intl || ''
        queryObject.origin = departure
        queryObject.destination = arrival
        queryObject.sft = checkIsIntl == 'y' ? '' : activeFare === '' ? '' : activeFare
        queryObject.isFF = isFFEnabled || (isFFFlowEnabled() && ffCheck)
        //FARE FAMILY TODO: Change this once the cookie logic is solved
        ;(queryObject.sd = new Date().getTime()), (queryObject.rnd_one = tripType)
        queryObject.isCfw = isCfwSelected && isCfwFlow({ isLoginMandatory: false }) ? true : false
        const utmObj = getUTM()
        queryObject = { ...queryObject, ...utmObj }
        let showToast = errorHandler(queryObject)
        setToast({
            errorMsg: '',
            closeBtn: false
        })

        if (showToast.validate) {
            setToast({
                errorMsg: showToast.msg,
                closeBtn: true
            })
        } else {
            updateHomeSearch({ ...queryObject, sourceCountry: city.source, destinationCountry: city.destination })
            // FARE FAMILY TODO: To remove this function when infra changes are made
            if (isFFFlowEnabled()) {
                let pathname = `/flights/results`
                    if (queryObject.intl && queryObject.intl === 'y') {
                        pathname = `/flights/international/results`
                    }
                    const stringify = toQueryString(queryObject)
                    browserHistory.push(`${pathname}?${decodeURIComponent(stringify)}`)
            }
            else {
                replaceQueryInUrl(queryObject)
            }
            
        }

        const { isIntl, intl } = city || {}
        const { depart_date, return_date, adults, childs, class: travelClass } = query || {}
        const payload = {
            origin: city?.sCode,
            destination: city?.dCode,
            journey_start_date: depart_date,
            journey_return_date: tripType === 'R' ? return_date : 'N/A',
            Adult_Count: adults,
            Child_Count: childs,
            section: 'Flights',
            flight_type: (city.isIntl || city.intl) === 'y' ? 'International' : 'Domestic',
            trip_type: tripType === 'R' ? 'round' : 'single'
        }
        dataLayerGA(payload, ACTION_NAMES.flightSearchClick)

        const ravenPayLoad = {
            action_name: RAVEN_ATTRIBUTE_NAME.HP_SEARCH_FLIGHT,
            page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
            a_trip_type: (city.isIntl || city.intl) === 'y' ? 'international' : 'domestic',
            a_journey_type: tripType === 'O' ? 'ow' : 'rt',
            a_fare_type: returnFareName(activeFare),
            a_return_date: tripType === 'R' ? formatDateStandardized(queryObject?.return_date) : 'N/A',
            a_dx: dayDiff(new Date(getFormattedDate(queryObject?.depart_date, 'MM/DD/YYYY', 'DD/MM/YYYY')), new Date()),
            a_adult_count: query.adults || 0,
            a_origin: city?.sCode,
            a_child_count: query.childs || 0,
            a_infant_count: query.infants || 0,
            a_departure_date: formatDateStandardized(queryObject?.depart_date),
            a_destination: city?.dCode
        }
        Raven.push({ eventName: RAVEN_EVENT_NAME.HOME_SEARCH_CLICK, eventData: ravenPayLoad })
    }

    useEffect(() => {
        let arrivalIntl = false
        let departureIntl = false
        if (!isEmpty(departure) && !isEmpty(arrival)) {
            arrivalIntl = departure?.split(', ')?.pop()?.trim() !== 'IN'
            departureIntl = arrival?.split(', ')?.pop()?.trim() !== 'IN'
        }
        setIsIntlArray([arrivalIntl, departureIntl])
    }, [departure, arrival])

    function isMoreThan24Hours(depart_date: string = "") {
        if(!depart_date) return false
        const [day, month, year] = depart_date?.split("/").map(Number)
        const departDate = new Date(year, month - 1, day)
        const now = new Date();
        const diffInMs = departDate - now;
        const diffInHours = diffInMs / (1000 * 60 * 60);
        return diffInHours > 24; // Returns true if more than 24 hours
    }

    const isVisaBannerAllowed = () => {
        const showVisaBanner = isMoreThan24Hours(query?.depart_date) && city?.sCode === "BLR"
        setShowVisaBanner(showVisaBanner)
    }

    return (
        <>
            <Container className={'flex flex-middle w-100p'}>
                <JourneyType journeyType={tripType} tripTypeHandler={tripTypeHandler} />
                <TravellerAndClassDropdown query={query} setQuery={setQuery} activeFare={activeFare} />
            </Container>

            <AirportField
                onArrivalChange={changeArrival}
                onDepartureChange={changeDeparture}
                error={{
                    arrival: city.arrivalError,
                    departure: city.departureError
                }}
                onSelectArrival={selectArrival}
                onSelectDeparture={selectDeparture}
                arrivalList={airportSearchList.arrival}
                departureList={airportSearchList.departure}
                departure={departure}
                arrival={arrival}
                loading={loading}
                departurePlaceholder={'Where from?'}
                arrivalPlaceholder={'Where to?'}
                city={city}
                setCity={setCity}
                setDeparture={setDeparture}
                setArrival={setArrival}
                toast={toast}
                isCfwSelected={isCfwSelected}
            />

            <Spacer className="mt-6" />
            <Container display={'flex'} flexDirection={'row'} columnGap={'24px'} justifyContent={'space-between'}>
                <Container className={'w-100p'}>
                    <DateRangePicker
                        roundTrip={tripType === 'R'}
                        onwardJourny={departure}
                        returnJourny={arrival}
                        departDate={query?.depart_date || ''}
                        returnDate={query?.return_date || ''}
                        dateClick={dateClick}
                        searchParams={query}
                        toCode={city.dCode}
                        fromCode={city.sCode}
                        journeyType={tripType}
                        showDateFare={!isCfwSelected}
                    />
                </Container>
            </Container>
            <SplFareType
                activeFare={activeFare}
                fareTypeList={fareList}
                handleClick={setActiveFareHandler}
                tripType={tripType}
            />
            <Spacer className="mt-6" />
            <Container className="flex flex-cg-6 flex-row flex-between">
                <CFWHomePageCallout
                    isIntlArray={isIntlArray}
                    setCfwErrorModal={setCfwErrorModal}
                    setCfwErrorModalButtonText={setCfwErrorModalButtonText}
                    activeFare={activeFare}
                    setIsCfwSelected={setIsCfwSelected}
                    isCfwSelected={isCfwSelected}
                    setCfwModal={setCfwModal}
                />
                <Button
                    color="secondary"
                    style={{ height: 'inherit', maxWidth: '280px' }}
                    onClick={searchHandler}
                    className='flex-1'
                    minWidth={"280px"}
                >
                    Search flights
                </Button>
            </Container>
            <CfwErrorModal
                cfwErrorModal={cfwErrorModal}
                cfwErrorModalButtonText={cfwErrorModalButtonText}
                isCfwSelected={isCfwSelected}
                setActiveFareHandler={setActiveFareHandler}
                setIsCfwSelected={setIsCfwSelected}
                isIntlArray={isIntlArray}
                setCfwErrorModal={setCfwErrorModal}
                setCfwErrorModalButtonText={setCfwErrorModalButtonText}
                setActiveFare={setActiveFare}
            />
            {!isServer() && (
                <CfwModalSSr
                    cfwModal={cfwModal}
                    setCfwModal={setCfwModal}
                    searchHandler={searchHandler}
                />
            )}
        </>
    )
}

export default HomeSearchContainer
