import { DAYS, MONTHS } from '@/constants/Srp'
import {getCookie} from "@/utils/general/cookie";

export const deepEqual: (x: any, y: any) => boolean = (x, y) => {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y
    return x && y && tx === 'object' && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
        : x === y
}

export const typeOf = (obj: any) => {
    let detv: any = typeof obj
    const isObject = (value: any) => value === 'object'
    const isArray = (value: any) => Object.prototype.toString.call(value) === '[object Array]'

    if (isObject(detv)) {
        if (obj) {
            if (isArray(obj)) {
                detv = 'array'
            }
        } else {
            detv = 'null'
        }
    }
    return detv
}

export const pad = (s: number) => {
    return s < 10 ? `0${s}` : s
}

export const formatDate = (date: any) => {
    const type = typeOf(date)
    let nDate
    if (type === 'number') {
        nDate = new Date(date)
    } else if (type === 'string') {
        nDate = new Date(parseInt(date, 10))
    } else {
        nDate = date
    }
    return `${DAYS[nDate.getDay()]}, ${pad(nDate.getDate())} ${MONTHS[nDate.getMonth()]}`
}

export const transformTimeStamp = (timeStamp: string) => {
    const dateString = new Date(Number(timeStamp)).toString()
    const [day, month, date, year, time] = dateString?.split(' ')
    const [hours, minutes, seconds] = time.split(':')
    return `${date} ${month}, ${hours}:${minutes}`
}

export const isFFFlowEnabled = () =>{
    const ffCookie = getCookie('ffEnabled')
    return ffCookie === 'true'
}