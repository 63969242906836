import React, { Dispatch, SetStateAction, useState } from 'react'
import classNames from 'classnames'
import Counter from './Counter'
import TooltipMessage from './TooltipMessage'
import { Container, Typography } from '@cleartrip/ct-design-components'
import { queryFormat } from '@/components/oldComponents/Dropdown/HomeSearchContainer'
import { Raven } from '@/analytics/raven'
import { RAVEN_ATTRIBUTE_NAME, RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'

// Define the types for props
interface TravellersProps {
    query: {
        adults: string
        childs: string
        infants: string
    }
    setQuery: (newQuery: Partial<queryFormat>) => void
    activeFare: string
}

// Define the types for the traveller object
interface Traveller {
    type: 'adults' | 'childs' | 'infants'
    label: string
    description: string
    minValue: number
    maxValue: number
}

// Define the types for the state
interface TravellersState {
    adults: string
    childs: string
    infants: string
}

const Travellers: React.FC<TravellersProps> = ({ query, setQuery, activeFare }) => {
    const TOTAL_TRAVELLER_COUNT = 9
    const shouldDisable = activeFare === 'student' || activeFare === 'senior_citizen'

    const [travellers, setTravellers] = useState<TravellersState>({
        adults: query.adults,
        childs: query.childs,
        infants: query.infants
    })

    const travellerUIArray: Traveller[] = [
        {
            type: 'adults',
            label: 'Adults',
            description: '12+ Years',
            minValue: 1,
            maxValue: TOTAL_TRAVELLER_COUNT - parseInt(travellers.childs)
        },
        {
            type: 'childs',
            label: 'Children',
            description: '2 - 12 yrs',
            minValue: 0,
            maxValue: TOTAL_TRAVELLER_COUNT - parseInt(travellers.adults)
        },
        {
            type: 'infants',
            label: 'Infants',
            description: 'Below 2 yrs',
            minValue: 0,
            maxValue: parseInt(travellers.adults)
        }
    ]

    function callRaven() {
        Raven.push({
            eventName: RAVEN_EVENT_NAME.HOME_PAX_UPDATED,
            eventData: {
                action_name: RAVEN_ATTRIBUTE_NAME.HP_PAX_UPDATED,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
            }
        })
    }

    function updateTravellers(type: 'adults' | 'childs' | 'infants', operation: 'increment' | 'decrement') {
        const increment = operation === 'increment' ? 1 : -1

        setTravellers(prevTravellers => {
            let newInfants = prevTravellers.infants

            if (
                type === 'adults' &&
                operation === 'decrement' &&
                parseInt(prevTravellers.infants) >= parseInt(prevTravellers.adults)
            ) {
                newInfants = (parseInt(prevTravellers.infants) - 1).toString()
            }

            return type === 'infants'
                ? {
                      ...prevTravellers,
                      [type]: (parseInt(prevTravellers[type]) + increment).toString()
                  }
                : {
                      ...prevTravellers,
                      [type]: (parseInt(prevTravellers[type]) + increment).toString(),
                      infants: newInfants
                  }
        })

        const currentQuery = { ...query }
        let newInfantsQuery = currentQuery.infants
        if (
            type === 'adults' &&
            operation === 'decrement' &&
            parseInt(currentQuery.infants) >= parseInt(currentQuery.adults)
        ) {
            newInfantsQuery = (parseInt(currentQuery.infants) - 1).toString()
        }

        const updatedQuery =
            type === 'infants'
                ? {
                      ...currentQuery,
                      [type]: (parseInt(currentQuery[type]) + increment).toString()
                  }
                : {
                      ...currentQuery,
                      [type]: (parseInt(currentQuery[type]) + increment).toString(),
                      infants: newInfantsQuery
                  }
        setQuery(updatedQuery)

        callRaven()
    }

    return (
        <>
            {travellerUIArray.map((traveller, index) => {
                const shouldShowTooltip = shouldDisable && traveller.type !== 'adults'
                return (
                    <TooltipMessage
                        key={index}
                        shouldDisable={shouldShowTooltip}
                        title={traveller.type}
                        sft={activeFare}
                        containerWidth="340px"
                        tooltipWidth="200px"
                    >
                        <Container
                            className={classNames(
                                'ls-reset br-4 w-100p px-2 dropdown__item flex flex-between flex-middle',
                                {
                                    'c-neutral-900': !shouldShowTooltip,
                                    'c-neutral-200': shouldShowTooltip
                                }
                            )}
                        >
                            <Container className="flex flex-column" style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                                <Typography variant={'B1'} className="lh-solid c-inherit-imp">
                                    {traveller.label}
                                </Typography>
                                <Typography
                                    variant={'B3'}
                                    className={`lh-solid ${shouldShowTooltip ? 'c-inherit-imp' : 'c-neutral-700'}`}
                                >
                                    {traveller.description}
                                </Typography>
                            </Container>
                            <Counter
                                handleSize={20}
                                value={parseInt(travellers[traveller.type])}
                                minValue={traveller.minValue}
                                maxValue={shouldShowTooltip ? 0 : traveller.maxValue}
                                onIncrement={() => updateTravellers(traveller.type, 'increment')}
                                onDecrement={() => updateTravellers(traveller.type, 'decrement')}
                            />
                        </Container>
                    </TooltipMessage>
                )
            })}
        </>
    )
}

export default Travellers
