import { ISuggestion } from '@/types/home'
import { Spinner } from '@cleartrip/ct-design-components'
interface DropDownListProps {
    airportList: ISuggestion[]
    onClick: (arg0: ISuggestion) => void
    onClose: () => void
    loading: boolean
}
const DropdownList = ({ airportList, onClick, onClose, loading }: DropDownListProps) => {
    return (
        <div className="dropdown p-absolute t-13 ln-1 w-100p" style={{ top: '52px' }}>
            {loading ? (
                <div className="px-10 py-5 flex flex-center flex-middle">
                    <Spinner />
                </div>
            ) : (
                airportList.map((item, index) => (
                    <ul key={index} className="airportList">
                        <li className="m-1">
                            <div
                                className="flex flex-middle"
                                onClick={() => {
                                    onClick(item)
                                    onClose()
                                }}
                            >
                                <div
                                    className="airport-code br-2 my-2 ml-3 mr-4 bg-neutral-300 c-neutral-700 flex fw-600 pt-1 pb-1 w-10"
                                    style={{ height: '22px', minWidth:'40px' }}
                                >
                                    <div className="fw-600" style={{ margin: 'auto', display: 'inline-block' }}>
                                        {item.k}
                                    </div>
                                </div>
                                <div style={{ maxWidth: '100%' }} className="mr-1 o-hidden">
                                    <p
                                        className="tt-ellipsis o-hidden fs-14 fw-500"
                                    >
                                        {item.v}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                ))
            )}
        </div>
    )
}

export default DropdownList
