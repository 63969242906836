import React, { useState, Dispatch, SetStateAction } from 'react'
import { Container, Spacer, Chip } from '@cleartrip/ct-design-components'
import { Dropdown, DropdownMenu, DropdownSelect } from '@/components/oldComponents/Dropdown'
import { PersonFilled, SelectedPerson, UpChevron } from '@/assets/icons'
import { propsTraveller } from '@/mocks/travellermock'
import Travellers from './Traveller/Traveller'
import { queryFormat } from '@/components/oldComponents/Dropdown/HomeSearchContainer'
import { RAVEN_ATTRIBUTE_NAME, RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'
import { Raven } from '@/analytics/raven'
import { ravenSDKTrigger } from '@/analytics/raven/ravenSDKHelper'

interface TravellerAndClassDropdownProps {
    query: queryFormat
    activeFare: string
    setQuery: (newQuery: Partial<queryFormat>) => void
}

const TravellerAndClassDropdown: React.FC<TravellerAndClassDropdownProps> = ({ query, setQuery, activeFare }) => {
    const [openMenu, setOpenMenu] = useState(false)

    const selectedClassName: Record<any, any> = {
        Economy: 'Economy',
        Business: 'Business class',
        First: 'First class',
        ['Premium Economy']: 'Premium economy'
    }
    const selectedClassNameRecord: Record<string, string> = {
        Economy: 'Economy',
        ['Business class']: 'Business',
        ['First class']: 'First',
        ['Premium economy']: 'Premium Economy'
    }
    function setClass(selectedclass: string) {
        Raven.push({
            eventName: RAVEN_EVENT_NAME.HOME_TRAVEL_CLASS_UPDATED,
            eventData: {
                action_name: RAVEN_ATTRIBUTE_NAME.HP_TRAVEL_CLASS_UPDATED,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
            }
        })
        setQuery({
            ...query,
            class: selectedClassNameRecord[selectedclass]
        })
    }
    const tripTypeDropdown = (
        <Container className="w-100p px-3 pt-3 pb-6">
            <Travellers query={query} setQuery={setQuery} activeFare={activeFare} />
            <Spacer className="pt-4" />
            <Container className="flex flex-middle flex-wrap flex-rg-3">
                {Object.values(selectedClassName).map((item, index) => {
                    return (
                        <Chip
                            styleConfig={{
                                labelTypography: {
                                    className: 'c-pointer fw-400'
                                },
                                root: {
                                    className: `c-pointer ${index === 1 ? 'mx-3' : ''}`
                                }
                            }}
                            key={index}
                            label={item}
                            isSelected={selectedClassName[query.class] === item}
                            onClick={() => {
                                setClass(item)
                            }}
                        />
                    )
                })}
            </Container>
        </Container>
    )
    const handleDropdownToggle = () => {
        setOpenMenu(!openMenu)
    }
    const getLabel = (open?: boolean) => {
        return (
            <>
                <Container className="flex flex-middle">
                    {open ? <SelectedPerson /> : <PersonFilled />}
                    <span className="fw-500 fs-16 ml-2" style={{ lineHeight: '24px' }}>
                        {`${parseInt(query.adults)} ${parseInt(query.adults) > 1 ? 'Adults' : 'Adult'}${
                            parseInt(query.childs)
                                ? `, ${query.childs} ${parseInt(query.childs) > 1 ? 'Children' : 'Child'}`
                                : ''
                        }${
                            parseInt(query.infants)
                                ? `, ${query.infants} ${parseInt(query.infants) > 1 ? 'Infants' : 'Infant'}`
                                : ''
                        }, ${selectedClassName[query.class]}`}
                    </span>
                    <span
                        className="ml-1 flex"
                        style={{ rotate: open ? '0deg' : '180deg', transition: 'ease .5s rotate' }}
                    >
                        <UpChevron />
                    </span>
                </Container>
            </>
        )
    }

    const travellerInputClick = () => {
        ravenSDKTrigger('a_hp_pax_travel_class_selection_clicked', {
            action_name: 'a_pax_travel_class_selection_click',
            page_name: 'flights_home'
        })
        setOpenMenu(!openMenu)
    }

    return (
        <Container className="homePageTraveller">
            <Dropdown
                onOutsideClick={() => {
                    setOpenMenu(false)
                }}
            >
                <DropdownSelect
                    data-testid='paxClassContainerId'
                    size={'sm'}
                    name="travellers"
                    getLabel={(open: boolean) => getLabel(open)}
                    placeHolder="Select traveller"
                    className="fs-2"
                    tabIndex={4}
                    onClick={travellerInputClick}
                    width={220}
                    toggleDropdown={handleDropdownToggle}
                    border={false}
                    open={openMenu}
                />
                <DropdownMenu className="dropDown_menu_widthImp" open={openMenu} distance={-12}>
                    {tripTypeDropdown}
                </DropdownMenu>
            </Dropdown>
        </Container>
    )
}

export default TravellerAndClassDropdown
